export const roamLogo = "/assets/svg/home-page/roam-icon-light.svg";
export const roamMobileLogo = "/assets/svg/sidenav/roam-mobile-logo.svg";

export const logo = {
  roamCircle: {
    src: "./assets/svg/roam.svg",
    alt: "logo",
  },
  roamOutline: {
    src: "./assets/svg/logo-outline.svg",
    alt: "logo",
  },
};

export const iconHomePage = {
  intro: {
    src: "/assets/svg/home-page/intro.svg",
    alt: "intro",
  },
  login: {
    src: "/assets/svg/home-page/login.svg",
    alt: "intro",
  },
};

export const iconSocmed = {
  like: {
    src: "/assets/svg/socmed/ic-love.svg",
    alt: "like",
  },
  liked: {
    src: "/assets/svg/socmed/ic-love-solid.svg",
    alt: "like",
  },
  comment: {
    src: "/assets/svg/socmed/ic-comment.svg",
    alt: "like",
  },
  share: {
    src: "/assets/svg/socmed/ic-share.svg",
    alt: "share",
  },
  facebook: {
    src: "/assets/svg/socmed/facebook.svg",
    alt: "share",
  },
  googleAccount: {
    src: "/assets/svg/socmed/google.svg",
    alt: "share",
  },
};

export const iconResident = {
  maintenanceRes: {
    src: "/assets/resident/gears.svg",
    alt: "maintenance",
  },
  insuranceRes: {
    src: "/assets/resident/insurance.svg",
    alt: "insurance",
  },
  violationRes: {
    src: "/assets/resident/violation.svg",
    alt: "insurance",
  },
  modificationRes: {
    src: "/assets/resident/fence.svg",
    alt: "modification",
  },
  announcementRes: {
    src: "/assets/resident/speaker.svg",
    alt: "announcement",
  },
};

export const iconTM = {
  door: {
    src: "/assets/svg/ic-door-ih.svg",
    alt: "door",
  },
  location: {
    src: "/assets/svg/ic-location-ih.svg",
    alt: "location",
  },
  owner: {
    src: "/assets/svg/ic-user-ih.svg",
    alt: "location",
  },
  calendar: {
    src: "/assets/svg/ic-calendar2-ih.svg",
    alt: "calendar",
  },
  time: {
    src: "/assets/svg/ic-time2-ih.svg",
    alt: "time",
  },
};

export const iconArrow = {
  solidDown: {
    src: "/assets/svg/ic-down-dd.svg",
    alt: "location",
  },
  chevronLeft: {
    src: "/assets/svg/chevron-left-icon.svg",
    alt: "chevron left",
  },
};

export const illustration = {
  submit: "./assets/svg/il-submition.svg",
  user: "./assets/svg/il-user.svg",
};

export const checkTimes = {
  checkRoundGreen: {
    src: "/assets/svg/check-green-round.svg",
    alt: "check",
  },
  closeRoundRed: {
    src: "/assets/svg/close-red-round.svg",
    alt: "times",
  },
  checkWhite: {
    src: "/assets/svg/check-white.svg",
    alt: "times",
  },
};

export const iconSignature = {
  title: {
    src: "/assets/svg/communication/ic-title.svg",
    alt: "Title",
  },
  textbox: {
    src: "/assets/svg/communication/ic-textbox.svg",
    alt: "Textbox",
  },
  signature: {
    src: "/assets/svg/communication/ic-signature.svg",
    alt: "Textbox",
  },
  radioButton: {
    src: "/assets/svg/communication/ic-radio button.svg",
    alt: "Radio Button",
  },
  person: {
    src: "/assets/svg/communication/ic-person.svg",
    alt: "Person",
  },
  email: {
    src: "/assets/svg/communication/ic-mail-address.svg",
    alt: "Mail Address",
  },
  initials: {
    src: "/assets/svg/communication/ic-initials.svg",
    alt: "Initials",
  },
  dropdown: {
    src: "/assets/svg/communication/ic-dropdown.svg",
    alt: "Dropdown",
  },
  dateSigned: {
    src: "/assets/svg/communication/ic-date-signed.svg",
    alt: "Date Signed",
  },
  company: {
    src: "/assets/svg/communication/ic-company.svg",
    alt: "Company",
  },
  checkbox: {
    src: "/assets/svg/communication/ic-checkbox.svg",
    alt: "Checkbox",
  },
};

export const iconLib = {
  arrow: { ...iconArrow },
  checkTimes: { ...checkTimes },
  accountCircle: {
    src: "/assets/svg/account-circle.svg",
    alt: "account",
  },
  folder: {
    src: "/assets/svg/flat-color-icons_folder.svg",
    alt: "nav left",
  },
  file: {
    src: "/assets/svg/ic-file.svg",
    alt: "File",
  },
  language: {
    src: "/assets/svg/language.svg",
    alt: "translate",
  },
  caretLeft: {
    src: "/assets/svg/ic-caret-left.svg",
    alt: "nav left",
  },
  caretRight: {
    src: "/assets/svg/ic-caret-right.svg",
    alt: "nav right",
  },
  caretTop: {
    src: "/assets/svg/ic-caret-top.svg",
    alt: "caret top",
  },
  contact: {
    src: "/assets/svg/contact.svg",
    alt: "contact",
  },
  mapPoint: {
    src: "/assets/svg/ic-pin.svg",
    alt: "map point",
  },
  more: {
    src: "/assets/svg/ic-more.svg",
    alt: "more",
  },
  plusTrWhite: {
    src: "/assets/svg/ic-plus-t-white.svg",
    alt: "plus",
  },
  roamAi: {
    src: "/assets/svg/ic-roam-ai.svg",
    alt: "roam ai",
  },
  roamAiBlack: {
    src: "/assets/svg/ic-roam-ai-black.svg",
    alt: "roam ai",
  },
  btnUpChange: {
    src: "/assets/svg/ic-button-change.svg",
    alt: "button up",
  },
  refresh: {
    src: "/assets/svg/ic-refresh.svg",
    alt: "refresh",
  },
  refreshRed: {
    src: "/assets/svg/ic-refresh-red.svg",
    alt: "refresh",
  },
  checkGreen: {
    src: "/assets/svg/check-icon-green.svg",
    alt: "checked",
  },
  check: {
    src: "/assets/svg/ic-check-ih.svg",
    alt: "checked",
  },
  sendRed: {
    src: "/assets/svg/ic-send-red.svg",
    alt: "send",
  },
  send: {
    src: "/assets/svg/ic-send.svg",
    alt: "send",
  },
  sendWhite: {
    src: "/assets/svg/send-white-icon.svg",
    alt: "send",
  },
  shareRed: {
    src: "/assets/svg/ic-share-red.svg",
    alt: "checked",
  },
  portalRed: {
    src: "/assets/svg/ic-portal-primary.svg",
    alt: "portal",
  },
  plusRed: {
    src: "/assets/svg/plus-icon.svg",
    alt: "plus",
  },
  plusRedCircle: {
    src: "/assets/svg/ic-add-circle.svg",
    alt: "plus",
  },
  phoneRed: {
    src: "/assets/svg/ic-phone.svg",
    alt: "phone",
  },
  tag: {
    src: "/assets/svg/ic-tag-dark.svg",
    alt: "tag",
  },
  tagWhite: {
    src: "/assets/svg/ic-tag.svg",
    alt: "tag",
  },
  closeWhite: {
    src: "/assets/svg/ic-close-white.svg",
    alt: "close",
  },
  closeSolid: {
    src: "/assets/svg/ic-close-black-solid.svg",
    alt: "close",
  },
  closeIh: {
    src: "/assets/svg/ic-close-ih.svg",
    alt: "close",
  },
  layout: {
    src: "/assets/svg/ic-layout.svg",
    alt: "layout",
  },
  inDoc: {
    src: "/assets/svg/ic-folder.svg",
    alt: "up document",
  },
  list: {
    src: "/assets/svg/ic-list.svg",
    alt: "up list",
  },
  share: {
    src: "/assets/svg/ic-share.svg",
    alt: "share",
  },
  link: {
    src: "/assets/svg/ic-link-black.svg",
    alt: "link",
  },
  bin: {
    src: "/assets/svg/ic-trash.svg",
    alt: "link",
  },
  binRed: {
    src: "/assets/svg/trash-bold-red.svg",
    alt: "link",
  },
  download: {
    src: "/assets/svg/ic-download.svg",
    alt: "link",
  },
  downloadRed: {
    src: "/assets/svg/ic-download-red.svg",
    alt: "link",
  },
  copy: {
    src: "/assets/svg/ic-copy.svg",
    alt: "link",
  },
  addImage: {
    src: "/assets/svg/ic-add-photo.svg",
    alt: "add image",
  },
  close: {
    src: "/assets/svg/remove-grey-icon.svg",
    alt: "close",
  },
  closeBlack: {
    src: "/assets/svg/ic-close-black.svg",
    alt: "close",
  },
  editBlack: {
    src: "/assets/svg/edit-black.svg",
    alt: "edit",
  },
  editPrimary: {
    src: "/assets/svg/ic-edit.svg",
    alt: "edit",
  },
  checkRound: {
    src: "/assets/svg/ic-check.svg",
    alt: "check",
  },
  checkRoundGreen: {
    src: "/assets/svg/check.svg",
    alt: "check",
  },
  closeRound: {
    src: "/assets/svg/ic-close.svg",
    alt: "close",
  },
  pdf: {
    src: "/assets/svg/bi_file-pdf.svg",
    alt: "close",
  },
  setting: {
    src: "/assets/svg/quill_cog.svg",
    alt: "setting",
  },
  calendar: {
    src: "/assets/svg/ic-calendar.svg",
    alt: "calendar",
  },
  calendarRed: {
    src: "/assets/svg/ic-calendar-red.svg",
    alt: "calendar",
  },
  moneyRound: {
    src: "/assets/svg/ic-money.svg",
    alt: "money",
  },
  unit: {
    src: "/assets/svg/ic-modification.svg",
    alt: "unit",
  },
  modificationGrey: {
    src: "/assets/svg/ic-modification-i.svg",
    alt: "unit",
  },
  shield: {
    src: "/assets/svg/ic-shield.svg",
    alt: "shield",
  },
  shieldI: {
    src: "/assets/svg/ic-shield-i.svg",
    alt: "shield",
  },
  violation: {
    src: "/assets/svg/ic-violation.svg",
    alt: "violation",
  },
  eyeRed: {
    src: "/assets/svg/ic-eye-red.svg",
    alt: "view",
  },
  icUp: {
    src: "/assets/svg/ic-up-black.svg",
    alt: "up",
  },
  newHouse: {
    src: "/assets/svg/ic-modification-house.svg",
    alt: "house",
  },
  fence: {
    src: "/assets/svg/ic-fence.svg",
    alt: "fence",
  },
  houseDraft: {
    src: "/assets/svg/ic-modification-draft.svg",
    alt: "house",
  },
  camera: {
    src: "/assets/svg/ic-camera.svg",
    alt: "camera",
  },
  user: {
    src: "/assets/svg/user.svg",
    alt: "user",
  },
  userGrey: {
    src: "/assets/svg/user-grey.svg",
    alt: "user",
  },
  userCircle: {
    src: "/assets/svg/user-circle.svg",
    alt: "user",
  },
  magic: {
    src: "/assets/svg/ic-magic.svg",
    alt: "magic",
  },
  edit: {
    src: "/assets/svg/ic-edit-white.svg",
    alt: "new",
  },
  shareWhite: {
    src: "/assets/svg/ic-share-white.svg",
    alt: "share",
  },
  arrowLeftBlack: {
    src: "/assets/svg/ic-left.svg",
    alt: "left",
  },
  arrowNext: {
    src: "/assets/svg/ic-arrow-next.svg",
    alt: "next",
  },
  searchGrey: {
    src: "/assets/svg/ic-search-grey.svg",
    alt: "left",
  },
  cog: {
    src: "/assets/svg/ic-cog.svg",
    alt: "announcement",
  },
  info: {
    src: "/assets/svg/ic-info-red.svg",
    alt: "info",
  },
  layoutColumn: {
    src: "/assets/svg/ic-layout-column.svg",
    alt: "layout",
  },
  refreshArrow: {
    src: "/assets/svg/ic-refresh-arrow.svg",
    alt: "refresh",
  },
  next: {
    src: "/assets/svg/arrow.svg",
    alt: "next",
  },
  filter: {
    src: "/assets/svg/ic-filter.svg",
    alt: "filter",
  },
  envelopeOpen: {
    src: "/assets/svg/ic-envelope-open.svg",
    alt: "envelope open",
  },
  arrowCollapse: {
    src: "/assets/svg/ic-arrow-collapse.svg",
    alt: "collapse expand",
  },
  fullScreen: {
    src: "/assets/svg/ic-full-screen.svg",
    alt: "fullscreen",
  },
  star: {
    src: "/assets/svg/ic-star.svg",
    alt: "star",
  },
  starred: {
    src: "/assets/svg/ic-starred.svg",
    alt: "star",
  },
  dotVertical: {
    src: "/assets/svg/more-black-icon.svg",
    alt: "more",
  },
  pencil: {
    src: "/assets/svg/edit-black.svg",
    alt: "edit",
  },
  desktop: {
    src: "/assets/svg/desktop.svg",
    alt: "desktop",
  },
  house: {
    src: "/assets/svg/house.svg",
    alt: "house",
  },
  add: {
    src: "/assets/svg/ic-add.svg",
    alt: "add",
  },
  searchBlack: {
    src: "/assets/svg/ic-search-black.svg",
    alt: "search",
  },
  infoBlack: {
    src: "/assets/svg/ic-info.svg",
    alt: "info",
  },
  upload: {
    src: "/assets/svg/upload-icon-black.svg",
    alt: "upload",
  },
  recentFile: {
    src: "/assets/svg/ic-recent-file.svg",
    alt: "recent file",
  },
  bilCheck: {
    src: "/assets/svg/ic-bill-check.svg",
    alt: "bill",
  },
  payBillCheck: {
    src: "/assets/svg/ic-pay-bill.svg",
    alt: "Pay Bill",
  },
  time: {
    src: "/assets/svg/ic-time.svg",
    alt: "time",
  },
  clock: {
    src: "/assets/svg/clock.svg",
    alt: "clock",
  },
  history: {
    src: "/assets/svg/ic-history.svg",
    alt: "history",
  },
  historyGrey: {
    src: "/assets/svg/history.svg",
    alt: "history",
  },
  message: {
    src: "/assets/svg/ic-message.svg",
    alt: "message",
  },
  phoneBlack: {
    src: "/assets/svg/ic-phone-black.svg",
    alt: "phone",
  },
  email: {
    src: "/assets/svg/ic-email.svg",
    alt: "email",
  },
  homeCheckBlack: {
    src: "/assets/svg/ic-home.svg",
    alt: "email",
  },
  homeMoveIn: {
    src: "/assets/svg/ic-move-in.svg",
    alt: "home move in",
  },
  cardCC: {
    src: "/assets/svg/ic-cc-card.svg",
    alt: "card",
  },
  rentKey: {
    src: "/assets/svg/ic-rent-key.svg",
    alt: "rent key",
  },
  deposit: {
    src: "/assets/svg/ic-r-deposit.svg",
    alt: "deposit",
  },
  fee: {
    src: "/assets/svg/ic-r-fees.svg",
    alt: "fee",
  },
  rentUser: {
    src: "/assets/svg/ic-r-user.svg",
    alt: "fee",
  },
  paw: {
    src: "/assets/svg/ic-r-pet-paw.svg",
    alt: "paw",
  },
  smoking: {
    src: "/assets/svg/ic-r-smoke.svg",
    alt: "smoke",
  },
  nCalendar: {
    src: "/assets/svg/ic-r-calendar.svg",
    alt: "calendar",
  },
  nDocCheck: {
    src: "/assets/svg/ic-doc-check.svg",
    alt: "document check",
  },
  nCreditCard: {
    src: "/assets/svg/ic-credit-card.svg",
    alt: "credit card",
  },
  ccInput: {
    src: "/assets/svg/cc-input.svg",
    alt: "credit card",
  },
  diamond: {
    src: "/assets/svg/ic-diamond.svg",
    alt: "diamond",
  },
};

export const iconMaintenanceCategory = {
  water: {
    src: "/assets/svg/maintenace/maintenance-plumbing.svg",
    alt: "water",
  },
  cooker: {
    src: "/assets/svg/maintenace/maintenance-appliances.svg",
    alt: "cooker",
  },
  electric: {
    src: "/assets/svg/maintenace/maintenance-electrical.svg",
    alt: "electric",
  },
  ac: {
    src: "/assets/svg/maintenace/maintenance-hvac.svg",
    alt: "hvac",
  },
  key: {
    src: "/assets/svg/maintenace/maintenance-keys-locks.svg",
    alt: "key and lock",
  },
  light: {
    src: "/assets/svg/maintenace/maintenance-lighting.svg",
    alt: "light",
  },
  fence: {
    src: "/assets/svg/maintenace/maintenance-outside.svg",
    alt: "fence",
  },
  tool: {
    src: "/assets/svg/maintenace/maintenance-general.svg",
    alt: "tool",
  },
};

export const iconPropertyTypes = {
  singleFamily: {
    src: "/assets/svg/property-types/property-types-single-family.svg",
    alt: "single family",
  },
  multiFamily: {
    src: "/assets/svg/property-types/property-types-multi-family.svg",
    alt: "multi family",
  },
  condo: {
    src: "/assets/svg/property-types/property-types-condo.svg",
    alt: "condo",
  },
  townhome: {
    src: "/assets/svg/property-types/property-types-townhome.svg",
    alt: "townhome",
  },
  camper: {
    src: "/assets/svg/property-types/property-types-camper.svg",
    alt: "camper/rv",
  },
  other: {
    src: "/assets/svg/property-types/property-types-other.svg",
    alt: "other",
  },
  barn: {
    src: "/assets/svg/property-types/property-types-barn.svg",
    alt: "barn",
  },
  container: {
    src: "/assets/svg/property-types/property-types-container.svg",
    alt: "container",
  },
};

export const iconPet = {
  petAllow: {
    src: "/assets/svg/pets/pets-allowed.svg",
    alt: "pet allowed",
  },
  petNotAllow: {
    src: "/assets/svg/pets/pets-not-allowed.svg",
    alt: "pet not allowed",
  },
};

export const iconParking = {
  parkAllow: {
    src: "/assets/svg/parking/parking-allowed.svg",
    alt: "pet allowed",
  },
  parkNotAllow: {
    src: "/assets/svg/parking/parking-not-allowed.svg",
    alt: "pet not allowed",
  },
};
